import React from "react"
import { Link, graphql } from "gatsby"

import Theme from "../utils/theme"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { rhythm } from "../utils/typography"
import { css } from "@emotion/react"

class BlogIndex extends React.Component {
  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title
    const posts = data.allMarkdownRemark.edges

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <Seo
          title="All posts"
          keywords={[`blog`, `data`, `engineering`, `developer`, `engineer`]}
        />
        {posts.map(({ node }) => {
          const title = node.frontmatter.title || node.fields.slug
          const snippet = node.frontmatter.description || node.excerpt
          return (
            <div
              key={node.fields.slug}
              css={css`
                a {
                  color: ${Theme.Colors.AccentDark};
                  text-decoration: underline;
                }
              `}
            >
              <h3
                style={{
                  marginBottom: rhythm(1 / 4),
                }}
              >
                <Link style={{ boxShadow: `none` }} to={node.fields.slug}>
                  {title}
                </Link>
              </h3>
              <small>{node.frontmatter.date}</small>
              <p>
                {snippet}
                {` `}
                <Link aria-label={`Read ${title}`} to={node.fields.slug}>
                  read
                </Link>
              </p>
            </div>
          )
        })}
      </Layout>
    )
  }
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "DD MMMM YYYY")
            title
            description
          }
        }
      }
    }
  }
`
